import React from 'react';
import logo from './logo.svg';
import './App.css';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ProfilePicture from './components/ProfilePicture';
import Introduction from './components/Introduction';
import Header from './components/Header';
import Affiliations from './components/Affilitations';
import ScholarInbox from './components/ScholarInbox';
import { useEffect, useState } from 'react';

function App() {
  const isMarkus = true;
  const renderIndex = true;
  document.title = "Flicke brothers"

  if (!renderIndex) {
    return (
      <div className="App">
        <Header is_markus={isMarkus} />
        <Box id="introduction-box" style={{margin: "auto", maxWidth: 860}}>
          <Box sx={{height: "260px"}}>
            <Introduction is_markus={isMarkus}/>
            <ProfilePicture is_markus={isMarkus}/>
          </Box>
          <Affiliations is_markus={isMarkus} />
          {(isMarkus) ? <ScholarInbox/> : null}
          {(!isMarkus) ? <Typography variant="h4" sx={{margin: "60px 0 20px 0"}}>Projects</Typography> : null}
        </Box>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Typography style={{fontSize: "3.5rem", marginTop: "50px"}}> <span style={{fontWeight: "500"}}>Flicke</span> brothers</Typography>
        <Typography variant='h4' sx={{marginBottom: "50px"}}>Machine learning meets mechanical engineering</Typography>
        <a href="https://carsten.flicke.eu"><Typography variant='h4'>Carsten Flicke</Typography></a>
        <a href="https://markus.flicke.eu"><Typography variant='h4'>Markus Flicke</Typography></a>
      </div>
    );
  }
}

export default App;
